import styled from 'styled-components';

const GreenButtonStyle = styled.button`
  width: 435px;
  height: 50px;
  font-size: 1.5rem;
  font-family: "Bebas Neue", serif;
  color: black;
  background-color: #B8FE01; 
  border: none;
  border-radius: 0;
  cursor: pointer;
  text-align: center;
  margin-top: 30px;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
  
  @media (max-width: 768px) {
        width: 335px;
    }
`;

export const GreenButton = ({ onClick, children }) => {
  return <GreenButtonStyle onClick={onClick}>{children}</GreenButtonStyle>;
};