import styled from "styled-components";
import React, {useState} from 'react';

const StyledInput = styled.input`
  width: 400px;
  height: 25px;
  padding: 1rem;
  font-size: 2rem;
  font-family: inherit;
  background-color: #1C1C1D;
  type: ${({ type }) => (type === "password" ? "password" : "text")};
  color: #FFF;
  text-align: left;
  border: ${({ isValid }) => (isValid ? 'none' : '0.1px solid red')};
  outline: ${({ isValid }) => (isValid ? 'none' : '0.1px solid red')};
  margin-top: 10px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};

  @media (max-width: 768px) {
    width: 300px;
  }

  &::placeholder {
    color: #444;
  }
`;

function TextField({ placeholder, value, setValue, isValid = true, type = "text", disabled = false }) {
  const [hasInteracted, setHasInteracted] = useState(false);

  const handleChange = (event) => {
    if (!disabled) {
      setHasInteracted(true);
      setValue(event.target.value);
      if (event.target.value === '') {
        setHasInteracted(false);
      }
    }
  };

  return (
    <StyledInput
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      isValid={hasInteracted ? isValid : true}
      type={type}
      disabled={disabled}
    />
  );
}


export default TextField;
