import React from 'react';
import styled from 'styled-components';

const Divider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  width: 50%;
  margin: ${({ margin }) => margin || '20px 0'};
  color: ${({ color }) => color || '#808080'};
`;

const Line = styled.div`
  flex-grow: 1;
  height: ${({ lineHeight }) => lineHeight || '1px'};
  background-color: ${({ lineColor }) => lineColor || '#dcdcdc'};
`;

const Text = styled.span`
  padding: ${({ textPadding }) => textPadding || '0 10px'};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  color: ${({ color }) => color || '#808080'};
`;

function CustomDivider({
  text = 'Or with email',
  color,
  lineColor,
  lineHeight,
  textPadding,
  fontSize,
  margin,
}) {
  return (
    <Divider color={color} margin={margin}>
      <Line lineColor={lineColor} lineHeight={lineHeight} />
      <Text
        color={color}
        textPadding={textPadding}
        fontSize={fontSize}
      >
        {text}
      </Text>
      <Line lineColor={lineColor} lineHeight={lineHeight} />
    </Divider>
  );
}

export default CustomDivider;
