import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {Body, SubTitle, Title} from '../../Components/Text';
import { useUser } from '../../Contexts/UserContext';
import {ScaleLoader} from "react-spinners";

const DashContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const TopContainer = styled.div`
  height: 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 90%;
    margin: 20px;
`

const TopContainerLeft = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 20px;
    margin-top: 20px;
    width: 20%;
`

const TopContainerMiddle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
`

const FloatingNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 50px;
  align-items: center;
  width: 50%;
  background-color: #1b1b1d;
`

const FloatingNavItem = styled.a`
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: .2s;
  position: relative;

  ${(props) => props.active && `
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 2px;
      background-color: white;
    }
  `}

  &:hover {
    transform: scale(1.05);
  }
`;

const TopContainerRight = styled.div`
    margin: 10px;
    width: 20%;
`

const TotalSessionsThisMonthWidget = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    text-align: end;
    width: 210px;
    background-color: #1b1b1d;
`

const BigWidget = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    text-align: end;
    width: 210px;
    background-color: #1b1b1d;
`


const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

function Dash() {
  const { user, isUserLoading } = useUser();
  const navigate = useNavigate();
  const date = new Date();

  const [isDashLoading, setIsDashLoading] = useState(true);

  const [activePage, setActivePage] = useState('/home');

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
    if(!isUserLoading)
        setIsDashLoading(false);
  }, [isUserLoading]);

  useEffect(() => {
    const path = window.location.pathname;
    setActivePage(path);
  }, [window.location.pathname]);

  return (
  <>
    {isDashLoading ? (
      <LoadingContainer>
        <ScaleLoader color="#B8FE01" height={70} width={10} />
      </LoadingContainer>
    ) : (
      <DashContainer>
        <TopContainer>
          <TopContainerLeft>
          </TopContainerLeft>
          <TopContainerMiddle>
            <FloatingNav>
              <FloatingNavItem active={activePage === '/home'} href="/home">Home</FloatingNavItem>
                <FloatingNavItem active={activePage === '/trends'} href="/trends">Trends</FloatingNavItem>
                <FloatingNavItem active={activePage === '/insights'} href="/insights">Insights</FloatingNavItem>
                <FloatingNavItem active={activePage === '/settings'} href="/settings">Settings</FloatingNavItem>
            </FloatingNav>
          </TopContainerMiddle>
          <TopContainerRight>
          </TopContainerRight>
        </TopContainer>
        <MainContainer>
            <Body style={{ color: '#B8FE01' }}>{formatDate(date)}</Body>
            <Title>Home</Title>
          <TotalSessionsThisMonthWidget>
            <Body style={{color: "#B8FE01"}}>total sessions this month</Body>
            <SubTitle>45</SubTitle>
          </TotalSessionsThisMonthWidget>
          <BigWidget>
            <Body style={{color: "#B8FE01"}}>total sessions this month</Body>
            <SubTitle>45</SubTitle>
          </BigWidget>
        </MainContainer>
      </DashContainer>
    )}
  </>
);
}

const formatDate = (date) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const dayOfWeek = days[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  const ordinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return `${dayOfWeek}, ${dayOfMonth}${ordinalSuffix(dayOfMonth)} ${month} ${year}`;
};

const getMessage = (displayName) => {

  const currentHour = new Date().getHours();
  const firstName = displayName.split(" ")[0];
  if (currentHour < 12){
    return `Good Morning, ${firstName}`
  }else if (currentHour < 18){
    return `Good Afternoon, ${firstName}`
  }else{
    return `Good Evening, ${firstName}`
  }
}

export default Dash;