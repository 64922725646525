import {LargeTitle, SubTitle, Body} from "../../Components/Text";
import styled from "styled-components";
import {auth} from "../../Firebase";
import {useEffect} from "react";

const HomeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    
    @media (max-width: 800px) {
        width: 100%;
    }
`


function Home() {

    useEffect(() => {
        console.log(auth.app.automaticDataCollectionEnabled)
    }, []);

 return (
    <HomeWrapper>
      <LargeTitle>Overload</LargeTitle>
         <SubTitle style={{color: "#B8FE01"}}>Coming soon</SubTitle>
        <Body style={{textAlign: "center"}}>Overload's companion web app enhances your mobile workout experience by providing in-depth insights and statistics on the big screen.
            Explore detailed performance trends, set personalised goals, and export your data effortlessly.</Body>
    </HomeWrapper>
  );
}

export default Home;
