import styled from "styled-components";

export const LargeTitle = styled.h1`
    //text-align: center;  
    font-size: 7rem;
    padding: 0;
    margin: 0;
    
    @media (max-width: 768px) {
        font-size: 4.5rem;
    }
`;
export const Title = styled.h1`
    //text-align: center;  
    font-size: 5rem;
    padding: 0;
    margin: 0;
    
    @media (max-width: 768px) {
        font-size: 2.8rem;
    }
`;

export const SubTitle = styled.h3`
    //text-align: center;
    font-size: 3rem;
    padding: 0;
    margin: 0;
`;

export const Body = styled.p`
    //text-align: center;
    font-size: 1.5rem;
    padding: 0;
    margin: 0;
    
    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
`;

export const Small = styled.p`
    text-align: center;
    font-size: 1rem;
    padding: 0;
    margin: 0;
`;

export const StyledLink = styled.a`
  color: #B8FF00; /* Link color */
  text-decoration: none;

  &:hover {
    text-decoration: underline; /* Underline on hover */
  }
`;